<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="position"
                v-on:input="search($event)"
                @reset="search($event)"
                type="search"
                placeholder="Enter Position you want.."
              />
              <b-input-group-append>
                <b-button @click="index(true, position)" variant="primary">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="4" class="my-1">
          <b-dropdown
            size="sm"
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Page Size"
            variant="primary"
          >
            <b-dropdown-item>
              <b-button
                variant="light"
                style="width: 100%"
                @click="pageresults1($event)"
                >50</b-button
              >
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button
                variant="light"
                style="width: 100%"
                @click="pageresults1($event)"
                >100</b-button
              >
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button
                variant="light"
                style="width: 100%"
                @click="pageresults1($event)"
                >100</b-button
              >
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <!-- Table -->

        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #cell(cv)="data">
              <b-button variant="primary" @click="showpdf(data.value)"
                >Click Here to View Full CV</b-button
              >
              <!-- <a download="Job Application Form" :href="data.value"
                >Click Here to Download</a
              > -->
              <br />
              or
              <br />
              <iframe :src="data.value"></iframe>
              <br />
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            v-on:input="paginate($event)"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BTable,
  BRow,
  BCol,
  BDropdownItem,
  BDropdown,
  BInputGroupAppend,
  BPagination,
  BContainer,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BCard,
  BInputGroup,
} from "bootstrap-vue";
import careersApi from "@/Api/Modules/careers";
import { jsPDF } from "jspdf";
export default {
  components: {
    BTable,
    BButton,
    BRow,
    BInputGroup,
    BCard,
    BCol,
    BPagination,
    BContainer,
    BInputGroupAppend,
    BForm,
    BFormGroup,
    BFormInput,
    BDropdownItem,
    BDropdown,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      dir: "ltr",
      position: "",
      id: "",
      // baseurl: "http://localhost:8080/sindhizBackend/Sindhizgroup_BE/public",
      currentPage: 1,
      perPage: "10",
      currentPage: "1",
      total: "",
      sortBy: "",
      items: [],
      careers: [],
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // colomns
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "cv", label: "CV", sortable: true },
        { key: "massage", label: "Massage", sortable: true },
        { key: "position", label: "Position", sortable: true },
      ],
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    await this.index();
  },

  methods: {
    showpdf(url) {
      var myWindow = window.open("", "fullscreen", "MsgWindow", "width=100%,height=100%");
      myWindow.document.write(
        "<iframe width='100%' height='100%' src='" + url + "'></iframe>"
      );
    },
    async index(reset = false, position = "") {
      await this.$vs.loading({
        scale: 0.8,
      });

      if (reset) {
        this.currentPage = 1;
        this.items = [];
      }
      const res = await careersApi.index(
        position,
        this.perPage,
        this.currentPage
      );

      if (this.currentPage === 1) {
        this.items = res.data.data.data;
      } else {
        this.items = this.items.concat(res.data.data.data);
      }
      this.total = res.data.data.pagination.total;

      this.$vs.loading.close();
    },

    search(e) {
      this.index(true, e);
    },
    paginate(e) {
      this.currentPage = e;
      this.index();
    },
    async pageresults1(e) {
      this.perPage = e.target.innerText;
      await this.index(true);
    },
    async pageresults2(e) {
      this.perPage = e.target.innerText;
      await this.index(true);
    },
    async pageresults3(e) {
      this.perPage = e.target.innerText;
      await this.index(true);
    },
  },
};
</script>
<style scoped></style>
